var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseView',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_c('div',{staticClass:"cards-title text-uppercase"},[_vm._v("beneficio")]),_c('div',{staticClass:"cards"},[_c('div',[_c('compras-card',{attrs:{"on-click":() =>
                _vm.redirect(
                  'Detalle beneficio acumulado anual',
                  'acumulado',
                  false
                ),"title":'Acumulado anual ' + _vm.datosCompras.beneficioAnual.ejercicio,"euros":_vm.datosCompras.beneficioAnual.beneficioTotal,"percent":_vm.datosCompras.beneficioAnual.cumplimiento,"data-info":_vm.datosCompras.fechaActulizacion,"percent-espe":_vm.datosCompras.beneficioAnual.percentEspe,"percent-para":_vm.datosCompras.beneficioAnual.percentPara,"percent-publi":_vm.datosCompras.beneficioAnual.percentPubli}})],1),_c('div',[_c('compras-card',{attrs:{"on-click":() =>
                _vm.redirect(
                  'Detalle beneficio ' +
                    _vm.datosCompras.beneficioMesCerrado.title,
                  'mes',
                  false
                ),"title":_vm.datosCompras.beneficioMesCerrado.title,"euros":_vm.datosCompras.beneficioMesCerrado.beneficioTotal,"percent":_vm.datosCompras.beneficioMesCerrado.cumplimiento,"data-info":_vm.datosCompras.fechaActulizacion,"percent-espe":_vm.datosCompras.beneficioMesCerrado.percentEspe,"percent-para":_vm.datosCompras.beneficioMesCerrado.percentPara,"percent-publi":_vm.datosCompras.beneficioMesCerrado.percentPubli}})],1)])]),_c('div',[_c('div',{staticClass:"cards-title text-uppercase"},[_vm._v("genéricos")]),_c('div',{staticClass:"cards"},[(
            _vm.datosCompras.genericosAnual &&
            _vm.datosCompras.genericosAnual.length > 0
          )?_c('div',[_c('compras-card-generic',{attrs:{"on-click":() =>
                _vm.redirect('Detalle genérico acumulado anual', 'anual', true),"title":'Acumulado anual ' + _vm.datosCompras.beneficioAnual.ejercicio,"labs":_vm.datosCompras.genericosAnual,"data-info":_vm.datosCompras.fechaActulizacion}})],1):_vm._e(),(
            _vm.datosCompras.genericosMes && _vm.datosCompras.genericosMes.length > 0
          )?_c('div',[_c('compras-card-generic',{attrs:{"on-click":() => _vm.redirect('Detalle genérico acumulado mes', 'mes', true),"title":_vm.datosCompras.beneficioMesCerrado.title,"labs":_vm.datosCompras.genericosMes,"data-info":_vm.datosCompras.fechaActulizacion}})],1):_vm._e()])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }