import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLogged = store.getters.isAuthenticated;

  if (to.meta.requiresAuth && !isLogged) {
    next('/login');
  } else if (to.meta.isAuth && isLogged) {
    next('/');
  } else {
    next();
  }
});

export default router;
