<template>
  <div class="filter-container">
    <farmacia-selector @update_filters="actualizarFarmacias" />
    <tipo-selector @update_filters="actualizarTipos" />
    <laboratorios-selector @update_filters="actualizarLaboratorios" />
    <tipo-articulo-selector @update_filters="actualizarArticulos" />
    <categorias-selector @update_filters="actualizarCategorias" />
    <code-input @update_filters="actualizarCode" />
    <b-button variant="primary" @click="filtrar">{{
      $t('button.apply')
    }}</b-button>
  </div>
</template>

<script>
import FarmaciaSelector from './selectoresFiltros/FarmaciaSelector.vue';
import LaboratoriosSelector from './selectoresFiltros/LaboratoriosSelector.vue';
import TipoSelector from './selectoresFiltros/TipoSelector.vue';
import TipoArticuloSelector from './selectoresFiltros/TipoArticuloSelector.vue';
import CategoriasSelector from './selectoresFiltros/CategoriaSelector.vue';
import CodeInput from './selectoresFiltros/CodeInput.vue';

export default {
  name: 'FiltroGeneral',
  components: {
    FarmaciaSelector,
    LaboratoriosSelector,
    TipoSelector,
    TipoArticuloSelector,
    CategoriasSelector,
    CodeInput,
  },
  data() {
    return {
      filtrosSeleccionado: {
        farmacias: [],
        tipos: [],
        tiposarticulo: [],
        laboratorios: [],
        categorias: [],
        cn: null,
      },
      farmaciasSeleccionadas: [],
      tiposSeleccionados: [],
      categoriasSeleccionadas: [],
      laboratoriosSeleccionados: [],
      articulosSeleccionados: [],
      productosSeleccionados: [],
      code: null,
    };
  },
  methods: {
    actualizarFarmacias(selectedItems) {
      this.farmaciasSeleccionadas = selectedItems;
    },
    actualizarTipos(selectedItems) {
      this.tiposSeleccionados = selectedItems;
    },
    actualizarCategorias(selectedItems) {
      this.categoriasSeleccionadas = selectedItems;
    },
    actualizarLaboratorios(selectedItems) {
      this.laboratoriosSeleccionados = selectedItems;
    },
    actualizarArticulos(selectedItems) {
      this.articulosSeleccionados = selectedItems;
    },
    actualizarProductos(selectedItems) {
      this.productosSeleccionados = selectedItems;
    },
    actualizarCode(code) {
      this.code = code;
    },

    filtrar() {
      const payload = {
        farmacias: this.farmaciasSeleccionadas,
        tipos: this.tiposSeleccionados,
        categorias: this.categoriasSeleccionadas,
        laboratorios: this.laboratoriosSeleccionados,
        tiposarticulo: this.articulosSeleccionados,
        productos: this.productosSeleccionados,
        cn: this.code,
      };
      this.$emit('aplicar-filtros', payload);
    },
  },
};
</script>

<style scoped>
.filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 2rem;
}
</style>
