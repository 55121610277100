<template>
  <div>
    <toast
      v-if="showToast"
      class="mt-2"
      type="success-filled"
      description="Exclusión guardada correctamente"
      @timeout="handleCloseToast"
      @close="handleCloseToast"
    />

    <card>
      <template #content>
        <b-row>
          <b-col>
            <h4>{{ title }}</h4>
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" @click="crear">{{
              $t('button.create')
            }}</b-button>
          </b-col>
        </b-row>
      </template>
      <template>
        <div v-if="items.length">
          <DataTableNaf
            :fields="columns"
            :items="items"
            :per-page="10"
            grid-cols="2fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr"
          >
            <template #cell(codigo)="{ item }">
              <span>{{ item.codigo }}</span>
            </template>
            <template #cell(nombre)="{ item }">
              <span>{{ item.nombre }}</span>
            </template>
            <template #cell(nombre_proveedor)="{ item }">
              <span>{{ item.nombre_proveedor }}</span>
            </template>
            <template #cell(user_fedicom)="{ item }">
              <span>{{ item.user_fedicom }}</span>
            </template>
            <template #cell(pass_fedicom)="{ item }">
              <span>{{ item.pass_fedicom }}</span>
            </template>
            <template #cell(volumen_pactado)="{ item }">
              <span>{{ item.volumen_pactado }}</span> </template
            ><template #cell(dto_inicial)="{ item }">
              <span>{{ item.dto_inicial }}%</span>
            </template>

            <template #cell(icons)="{ item }">
              <div class="d-flex justify-content-between">
                <div class="pr-2">
                  <span
                    class="icon icon-edit-outline"
                    @click="editar(item)"
                  ></span>
                </div>
                <div>
                  <span
                    class="icon icon-trash-outline"
                    @click="openConfirmacion(item)"
                  ></span>
                </div>
              </div>
            </template>
          </DataTableNaf>
        </div>
        <card-item-simple v-if="!items.length">
          <template #description>
            <div>{{ $t('sinResultados') }}</div>
          </template>
        </card-item-simple>
      </template>
    </card>
    <modal-farmacias
      v-if="showModal"
      :id="itemSelected"
      :item="texto"
      :es-nuevo="esNuevo"
      @close="close"
      @save="loadItems"
    />
    <modal-confirmacion
      v-if="showConfirmation"
      @close="close"
      @confirm="borrar"
    />
  </div>
</template>

<script>
import farmacias from '@/api/farmacias.api';
import ModalFarmacias from './ModalFarmacias.vue';
import colors from '@/mixins/colors.js';
export default {
  name: 'ListadoFarmacias',
  components: {
    ModalFarmacias,
  },
  extends: farmacias,
  mixins: [colors],
  data() {
    return {
      title: 'Listado de farmacias',
      showModal: false,
      showConfirmation: false,
      items: [],
      esNuevo: false,
      texto: {},
      showToast: false,
      columns: [
        {
          key: 'codigo',
          sortable: true,
          label: 'Código',
        },
        {
          key: 'nombre',
          sortable: true,
          label: 'Nombre',
        },
        {
          key: 'nombre_proveedor',
          sortable: true,
          label: 'Proveedor',
        },
        {
          key: 'user_fedicom',
          sortable: true,
          label: 'Usuario fedicom',
        },
        {
          key: 'pass_fedicom',
          sortable: true,
          label: 'Contraseña fedicom',
        },
        {
          key: 'volumen_pactado',
          sortable: true,
          label: 'Volumen pactado',
          class: 'justify-content-center',
        },
        {
          key: 'dto_inicial',
          sortable: true,
          label: 'Descuento inicial',
          class: 'justify-content-center',
        },
        {
          key: 'icons',
          sortable: false,
          label: '',
          class: 'justify-content-end',
        },
      ],
      request: {},
      loading: true,
      itemToDelete: null,
    };
  },
  watch: {
    request: function () {
      this.loadItems();
    },
    reload: function () {
      this.loadItems();
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    loadItems: function () {
      let loadPromise = new Promise((resolve) => {
        this.getFarmacias(resolve, this.request);
      });
      loadPromise.then((data) => {
        this.items = [];
        this.items = data;
      });
    },
    editar: function (item) {
      this.esNuevo = false;
      this.texto = {
        ...item,
      };
      this.itemSelected = item.id_farmacia;
      this.showModal = true;
    },
    crear: function (event) {
      this.esNuevo = true;
      this.texto = {};
      this.itemSelected = null;
      this.showModal = true;
    },
    borrar: function (item) {
      console.log('borrarrrr ', this.itemToDelete);
      this.showConfirmation = false;
      if (this.itemToDelete) {
        let deletePromise = new Promise((resolve) => {
          this.deleteFarmacia(resolve, item, this.itemToDelete);
        });
        deletePromise.then((data) => {
          this.loadItems();
        });
      }
    },
    close: function (event) {
      this.showConfirmation = false;
      this.showModal = false;
    },
    openConfirmacion: function (item) {
      console.log('openconfirmation', item);
      this.itemToDelete = item.id_farmacia;
      this.showConfirmation = true;
    },
    handleCloseToast() {
      this.showToast = false;
    },
  },
};
</script>

<style lang="scss" scope></style>
