import Dashboard from '../views/Dashboard';
import Login from '../views/Login';
import Compras from '../views/compras/Compras';
import ResumenCompras from '../views/compras/ResumenCompras';
import BiCruzfarmaRed from '../views/BiCruzfarmaRed';
import Usuario from '../views/dashboard/admin/Usuario.vue';
import Farmacia from '../views/dashboard/admin/Farmacia.vue';
import Proveedor from '../views/dashboard/admin/Proveedor.vue';
import HistoricoCompras from '../views/compras/HistoricoCompras.vue';
import Descuentos from '../views/dashboard/admin/Descuentos.vue';
import ControlFarmacias from '../views/dashboard/admin/ControlFarmacias.vue';
import ResetPassword from '../views/ResetPassword';

import store from '../store/index';

const routes = [
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      requiresAuth: false,
      isAuth: true,
    },
  },
  {
    path: '/resetpassword',
    component: ResetPassword,
    name: 'resetpassword',
    meta: {
      requiresAuth: false,
      isAuth: true,
    },
  },
  {
    path: '/',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      isAuth: false,
    },
    redirect: '/compras',
    children: [
      {
        path: '/compras',
        component: Compras,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
        exact: true,
      },
      {
        path: '/historico',
        component: HistoricoCompras,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
      },
      {
        path: '/control',
        component: ControlFarmacias,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
      },
      {
        path: '/bi-cruzfarma-red',
        component: BiCruzfarmaRed,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
        exact: true,
        beforeEnter: (to, from, next) => {
          const request = {};
          store
            .dispatch('bicruzfarmared/loadTokenSellout', request)
            .then(() => {
              const urlData = store.state.bicruzfarmared.tokenSellout;
              window.open(urlData, '_blanket');

              if (urlData) {
                next(false);
              } else {
                console.error('No se pudo obtener el token sellout');
                next(true);
              }
            })
            .catch((error) => {
              console.error('Error al cargar el token sellout:', error);
              next(false);
            });
        },
      },
      {
        path: '/compras/resumen',
        component: ResumenCompras,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
      },
      {
        path: '/usuarios',
        component: Usuario,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
      },
      {
        path: '/farmacias',
        component: Farmacia,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
      },
      {
        path: '/proveedores',
        component: Proveedor,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
      },
      {
        path: '/descuentos',
        component: Descuentos,
        meta: {
          requiresAuth: true,
          isAuth: false,
        },
      },
    ],
  },
];

export default routes;
