<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form :style="combinedStyles" title="Informacion básica">
          <template>
            <card-form-item title="Nombre">
              <template #description>
                <input-box
                  v-model="itemMod.name"
                  v-bind="form.name"
                  :errors="form.name.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
            <card-form-item title="Email">
              <template #description>
                <input-box
                  v-model="itemMod.email"
                  v-bind="form.email"
                  :errors="form.email.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
            <card-form-item title="Rol">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_rol"
                    :filter="true"
                    :options="opcionesRoles"
                    v-bind="form.rol"
                    :errors="form.rol.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>
            <card-form-item title="Farmacia">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_farmacia"
                    :filter="true"
                    :options="opcionesFarmacias"
                    v-bind="form.farmacia"
                    :errors="form.farmacia.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import moment from 'moment';
import usuarios from '@/api/usuarios.api';
import colors from '@/mixins/colors.js';
import CheckSelector from '../../commons/forms/CheckSelector.vue';
import MultipleSelectCustom from '../../commons/forms/MultipleSelectCustom.vue';
import CheckSelectorCollapseLoad from '../../commons/forms/CheckSelectorCollapseLoad.vue';

import { mapGetters } from 'vuex';
export default {
  name: 'ModalUsuarios',
  components: {
    MultipleSelectCustom,
    CheckSelectorCollapseLoad,
  },
  extends: usuarios,
  mixins: [colors],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      opcionesRoles: [],
      opcionesFarmacias: [],
      // ablitiesOptions: [],
      itemMod: {},
      form: {
        name: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        email: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        rol: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        farmacia: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      propio: false,
      esEstandar: true,
    };
  },
  watch: {
    item: {
      handler: function (req) {
        if (Object.keys(this.item).length > 0) {
          this.loadFiltros().then(() => {
            this.cargarDatosIniciales();
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadFiltros().then(() => {
      this.cargarDatosIniciales();
    });
  },

  methods: {
    cargarDatosIniciales() {
      if (this.esNuevo) {
        this.itemMod = {
          name: '',
          email: '',
          id_rol: null,
          id_farmacia: null,
        };
      } else {
        this.itemMod = {
          ...this.item,
        };
      }
    },
    async loadFiltros() {
      try {
        const farmaciasData = await new Promise((resolve) =>
          this.getFarmacias(resolve)
        );
        this.opcionesFarmacias = farmaciasData.map((item) => ({
          text: item.nombre,
          value: item.id_farmacia,
        }));

        const rolesData = await new Promise((resolve) =>
          this.getRoles(resolve)
        );
        this.opcionesRoles = rolesData.map((item) => ({
          text: item.descripcion,
          value: item.id_rol,
        }));
      } catch (error) {
        console.log(error);
      }
    },

    actualizarCheck: function (data) {
      console.log(data);
    },
    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createUsuarios(resolve, this.itemMod);
        });
        createPromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateUsuarios(resolve, this.itemMod, this.itemMod.id);
        });
        updatePromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },
    validateForm() {
      let validado = true;

      if (!this.itemMod.name) {
        this.form.name.hasError = true;
        this.form.name.errors.push({
          show: true,
          message: 'Introduce un nombre',
        });
        validado = false;
      } else {
        this.form.name.hasError = false;
        this.form.name.errors = [];
      }

      if (!this.itemMod.email) {
        this.form.email.hasError = true;
        this.form.email.errors.push({
          show: true,
          message: 'Introduce un email',
        });
        validado = false;
      } else {
        this.form.email.hasError = false;
        this.form.email.errors = [];
      }

      if (!this.itemMod.id_rol) {
        this.form.rol.hasError = true;
        this.form.rol.errors.push({
          show: true,
          message: 'Selecciona un rol',
        });
        validado = false;
      } else {
        this.form.rol.hasError = false;
        this.form.rol.errors = [];
      }

      // if (!this.itemMod.id_farmacia) {
      //   this.form.farmacia.hasError = true;
      //   this.form.farmacia.errors.push({
      //     show: true,
      //     message: 'Selecciona una farmacia',
      //   });
      //   validado = false;
      // } else {
      //   this.form.farmacia.hasError = false;
      //   this.form.farmacia.errors = [];
      // }

      return validado;
    },
    cleanForm: function () {
      this.form = {
        name: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        email: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        rol: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        farmacia: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.form {
  overflow: visible;
}
</style>
