import store from '../store';
import { saveAs } from 'file-saver';

const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const END_POINT = '/compras/';
export default {
  methods: {
    getComprasDetalleCategorizacion: function (
      resolve,
      catFilter,
      nivelCategorizacion,
      periodo,
      genericos
    ) {
      httpClient
        .post(END_POINT + 'detallecategorizacion', {
          catFilter: catFilter,
          nivelCategorizacion: nivelCategorizacion,
          periodo: periodo,
          genericos: genericos,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
