<template>
  <div>
    <div class="w-full mb-4">
      <div
        :id="`inputfile-container-${uuid}`"
        :style="{ marginLeft: 'auto', textAlign: 'right' }"
      >
        <span v-if="help" class="d-block help-text">
          {{ help }}
        </span>
        <input
          v-show="false"
          ref="input"
          type="file"
          :accept="accept"
          @change="handleFileUpload"
        />
        <b-button variant="outline-primary" @click="openFileSelect">
          Importar descuentos por artículo
        </b-button>
      </div>
    </div>

    <filtros-listado-descuento @aplicar-filtros="handleAplicarFiltros" />
    <toast
      v-if="showToast"
      class="mt-2"
      type="success-filled"
      description="Exclusión guardada correctamente"
      @timeout="handleCloseToast"
      @close="handleCloseToast"
    />

    <card>
      <template #content>
        <b-row>
          <b-col>
            <h4>{{ title }}</h4>
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" @click="crear">{{
              $t('button.create')
            }}</b-button>
          </b-col>
        </b-row>
      </template>
      <template>
        <div v-if="items.length">
          <DataTableNaf
            :fields="columns"
            :items="items"
            :per-page="10"
            grid-cols="3fr 1fr 2fr 2fr 2fr 2fr 2fr 1fr "
          >
            <template #cell(farmacia_nombre)="{ item }">
              <span>{{ item.farmacia?.nombre || '' }}</span>
            </template>
            <template #cell(tipo)="{ item }">
              <span>{{ item.tipo }}</span>
            </template>
            <template #cell(laboratorio_nombre)="{ item }">
              <span>{{ item.laboratorio ? item.laboratorio.nombre : '' }}</span>
            </template>
            <template #cell(categoria_categoria)="{ item }">
              <span>{{ item.categoria.categoria }}</span>
            </template>
            <template #cell(cn)="{ item }">
              <span>{{ item.cn }}</span>
            </template>
            <template #cell(dto)="{ item }">
              <span>{{ item.dto ? item.dto + ' %' : '' }}</span>
            </template>

            <template #cell(icons)="{ item }">
              <div class="d-flex justify-content-between">
                <div class="pr-2">
                  <span
                    class="icon icon-edit-outline"
                    @click="editar(item)"
                  ></span>
                </div>
                <div>
                  <span
                    class="icon icon-trash-outline"
                    @click="openConfirmacion(item)"
                  ></span>
                </div>
              </div>
            </template>
          </DataTableNaf>
        </div>
        <card-item-simple v-if="!items.length">
          <template #description>
            <div>{{ $t('sinResultados') }}</div>
          </template>
        </card-item-simple>
      </template>
    </card>

    <modal-descuentos
      v-if="showModal"
      :id="itemSelected"
      :item="texto"
      :es-nuevo="esNuevo"
      @close="close"
      @save="loadItems"
    />
    <modal-confirmacion
      v-if="showConfirmation"
      @close="close"
      @confirm="borrar"
    />
    <ModalUploadCSV
      v-if="showModalCSV"
      :response="responseUploadFile"
      :response-error="responseError"
      @close="close"
      @confirm="close"
    />
  </div>
</template>

<script>
import BButton from 'frontendcommon/src/components/design-system/BButton.vue';
import uuid from 'frontendcommon/src/mixins/uuid';
import descuentos from '@/api/farmaciaDescuento.api';
import ModalDescuentos from './ModalDescuentos.vue';
import ModalUploadCSV from './ModalUploadCSV';

import FiltrosListadoDescuento from './FiltrosListadoFarmacias/FiltrosListadoDescuentos.vue';
import colors from '@/mixins/colors.js';

export default {
  name: 'ListadoDescuentos',
  components: {
    ModalDescuentos,
    FiltrosListadoDescuento,
    ModalUploadCSV,
    BButton,
  },
  extends: descuentos,
  mixins: [colors, uuid],
  data() {
    return {
      title: 'Descuentos',
      showModal: false,
      showConfirmation: false,
      showModalCSV: false,
      responseUploadFile: {},
      responseError: '',
      items: [],
      esNuevo: false,
      texto: {},
      showToast: false,
      file: null,
      columns: [
        {
          key: 'farmacia_nombre',
          label: 'Farmacia',
        },
        {
          key: 'tipo',
          sortable: true,
          label: 'Tipo',
        },
        {
          key: 'tipo_articulo_desc',
          sortable: true,
          label: 'Tipo artículo',
        },
        {
          key: 'categoria',
          sortable: true,
          label: 'Categoría',
        },
        {
          key: 'laboratorio_nombre',
          sortable: true,
          label: 'Laboratorio',
        },
        {
          key: 'cn',
          sortable: true,
          label: 'Código',
          class: 'justify-content-center',
        },
        {
          key: 'dto',
          sortable: true,
          label: 'Descuento',
          class: 'justify-content-center',
        },
        {
          key: 'icons',
          sortable: false,
          label: '',
          class: 'justify-content-end',
        },
      ],
      request: {},
      loading: true,
      itemToDelete: null,
      accept: '.csv',
      help: 'Solo archivos .csv',
      requiredLabel: false,
      requiredLabelText: '(Obligatorio)',
    };
  },
  watch: {
    request: function () {
      this.loadItems();
    },
    reload: function () {
      this.loadItems();
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    loadItems: function () {
      let loadPromise = new Promise((resolve) => {
        this.getDescuentos(resolve, this.request);
      });
      loadPromise.then((data) => {
        this.items = data.map((item) => ({
          ...item,
          farmacia_nombre: item.farmacia?.nombre,
          laboratorio_nombre: item.laboratorio?.nombre,
          categoria: item.categoria,
        }));
      });
    },

    openFileSelect() {
      this.$refs.input.click();
    },

    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        await this.uploadFile(file);

        this.$refs.input.value = null;
      }
    },
    async uploadFile(file) {
      try {
        const response = await this.uploadCSVFarmaciaDescuento(file);
        this.responseUploadFile = response.errores;
        if (this.responseUploadFile.length === 0) {
          this.loadItems();
        }
      } catch (error) {
        this.responseError = error;
      }
      this.showModalCSV = true;
    },

    deleteFile() {
      this.file = null;
      this.$emit('input', {}); // Emite el archivo vacío
    },

    editar: function (item) {
      this.esNuevo = false;
      this.texto = {
        ...item,
      };
      this.itemSelected = item.id_farmacia_descuento;
      this.showModal = true;
    },
    crear: function (event) {
      this.itemSelected = null;
      this.esNuevo = true;
      this.texto = {};
      this.showModal = true;
    },
    borrar: function (item) {
      this.showConfirmation = false;
      if (this.itemToDelete) {
        let deletePromise = new Promise((resolve) => {
          this.deleteFarmaciaDescuento(resolve, item, this.itemToDelete);
        });
        deletePromise.then((data) => {
          this.loadItems();
        });
      }
    },
    close: function (event) {
      this.showConfirmation = false;
      this.showModal = false;
      this.showModalCSV = false;
    },
    closeModalCSV: function (event) {
      this.showModalCSV = false;
    },
    openConfirmacion: function (item) {
      this.itemToDelete = item.id_farmacia_descuento;
      this.showConfirmation = true;
    },
    handleCloseToast() {
      this.showToast = false;
    },
    handleAplicarFiltros(filtros) {
      this.request = { ...this.request, ...filtros };
      this.loadItems();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'frontendcommon/src/scss/config';

.help-text {
  font-style: italic;
  color: #505659;
  font-size: x-small;
}

.inputfile-container {
  width: fit-content;

  label {
    margin-bottom: 5px;
  }

  .help-text {
    @include h8();
    font-style: italic;
    color: #505659;
    font-size: x-small;
  }

  button {
    margin-top: 10px;
    min-width: 170px;
  }

  &.has-value {
    button {
      background: $soft-grey !important;
      border: $soft-grey !important;
      color: $dark-grey !important;
    }
  }

  &.background-dark {
    label {
      color: rgb(26, 19, 19);
    }
    .help-text {
      color: white;
    }
  }
}
</style>
