import store from '../store';
import { saveAs } from 'file-saver';

const accessToken = store.getters.getAccessToken;
import httpClient from './httpClient';

const END_POINT = '/';
export default {
  methods: {
    getDatosCompras: function (resolve, request) {
      httpClient
        .get(END_POINT + 'home/obtener-datos', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(Promise.reject(error.response));
        });
    },
    getFotoLab: function (resolve, idlaboratorio) {
      httpClient
        .get(END_POINT + 'laboratorio/foto/' + idlaboratorio)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(Promise.reject(error.response));
        });
    },
    getDatosControlFarmaciasAdmin: function (resolve, request) {
      httpClient
        .get(END_POINT + 'home/obtener-datos-admin', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(Promise.reject(error.response));
        });
    },
  },
};
