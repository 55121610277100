<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form :style="combinedStyles" title="Información básica">
          <template>
            <card-form-item title="Farmacia">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_farmacia"
                    :filter="true"
                    :options="opcionesFarmacia"
                    v-bind="form.nombre_farmacia"
                    :errors="form.nombre_farmacia.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item title="Tipo">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.tipo"
                    :options="tiposOpciones"
                    :filter="true"
                    placeholder="Seleccione un tipo"
                    v-bind="form.tipo"
                    :errors="form.tipo.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item v-if="itemMod.tipo === 'C'" title="Tipo artículo">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.tipo_articulo_codigo"
                    :filter="true"
                    :options="opcionesTipo"
                    v-bind="form.tipo_articulo_codigo"
                    :errors="form.tipo_articulo_codigo.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item v-if="itemMod.tipo === 'C'" title="Categoría">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_categoria"
                    :filter="true"
                    :options="opcionesCategoria"
                    v-bind="form.id_categoria"
                    :errors="form.id_categoria.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item v-if="itemMod.tipo === 'C'" title="Laboratorio">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_laboratorio"
                    :filter="true"
                    :options="opcionesLaboratorio"
                    v-bind="form.id_laboratorio"
                    :errors="form.id_laboratorio.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item v-if="itemMod.tipo === 'P'" title="Código Producto">
              <template #description>
                <div>
                  <input-box
                    v-model="itemMod.codigo_producto"
                    type="number"
                    v-bind="form.codigo_producto"
                    :errors="form.codigo_producto.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>

            <card-form-item title="Descuento">
              <template #description>
                <input-box
                  v-model="itemMod.dto"
                  type="number"
                  v-bind="form.dto"
                  :errors="form.dto.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
          </template>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import farmacias from '@/api/farmacias.api';
import farmaciaDescuento from '@/api/farmaciaDescuento.api';
import proveedores from '@/api/proveedores.api';
import laboratorios from '@/api/laboratorios.api';
import catalogoSellout from '@/api/catalogoSellout.api';
import colors from '@/mixins/colors.js';
import CheckSelector from '../../commons/forms/CheckSelector.vue';
import MultipleSelectCustom from '../../commons/forms/MultipleSelectCustom.vue';

export default {
  name: 'ModalFarmacias',
  components: { MultipleSelectCustom, CheckSelector },
  extends: farmacias,
  laboratorios,
  farmaciaDescuento,
  mixins: [
    colors,
    proveedores,
    laboratorios,
    farmacias,
    farmaciaDescuento,
    catalogoSellout,
  ],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      itemMod: {},
      opcionesFarmacia: [],
      opcionesLaboratorio: [],
      opcionesCategoria: [],
      opcionesTipo: [],
      tiposOpciones: [
        { text: 'Farmacia (F)', value: 'F' },
        { text: 'Categoría (C)', value: 'C' },
        { text: 'Producto (P)', value: 'P' },
      ],
      form: {
        nombre_farmacia: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        id_categoria: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        tipo_articulo_codigo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        id_laboratorio: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        dto: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        tipo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        codigo_producto: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  watch: {
    'itemMod.id_categoria': function (newVal) {
      console.log('Nuevo valor de id_categoria:', newVal);
    },
  },
  mounted() {
    this.loadFiltros();
    console.log('Mounted: Opciones y datos iniciales cargados');
  },

  methods: {
    loadDatosFarmacia() {
      if (this.id) {
        let createPromise = new Promise((resolve) => {
          this.showFarmaciaDescuento(resolve, this.id);
        });
        createPromise.then((data) => {
          if (data) {
            this.itemMod = data;
            if (this.itemMod.laboratorio) {
              this.itemMod.id_laboratorio =
                this.itemMod.laboratorio.id_laboratorio;
            }
            if (this.itemMod.categoria) {
              this.itemMod.id_categoria = String(
                this.itemMod.categoria.id_categoria
              );
            }

            this.itemMod.codigo_producto = this.itemMod.cn;

            console.log('Valor inicial de catt:', this.itemMod.categoria);
          }
        });
      }
    },

    guardar() {
      this.cleanForm();

      if (!this.validateForm()) {
        return;
      }

      const selectedCategoria = this.opcionesCategoria.find(
        (option) => option.value === this.itemMod.id_categoria
      );

      const selectedTipoArticulo = this.opcionesTipo.find(
        (option) => option.value === this.itemMod.tipo_articulo_codigo
      );

      const datosAEnviar = {
        id_farmacia: String(this.itemMod.id_farmacia),
        tipo: this.itemMod.tipo,
        id_laboratorio: this.itemMod.id_laboratorio
          ? String(this.itemMod.id_laboratorio)
          : null,
        id_categoria: this.itemMod.id_categoria || null,
        categoria: selectedCategoria ? selectedCategoria.text : null,
        tipo_articulo_codigo: this.itemMod.tipo_articulo_codigo || null,
        tipo_articulo_desc: selectedTipoArticulo
          ? selectedTipoArticulo.text
          : null,
        cn: this.itemMod.codigo_producto || '',
        dto: this.itemMod.dto || '',
      };

      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createFarmaciaDescuento(resolve, datosAEnviar);
        });
        createPromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateFarmaciaDescuento(
            resolve,
            datosAEnviar,
            this.itemMod.id_farmacia_descuento
          );
        });
        updatePromise.then((data) => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },

    async loadFiltros() {
      try {
        const farmaciasData = await new Promise((resolve) =>
          this.getFarmacias(resolve)
        );
        this.opcionesFarmacia = farmaciasData.map((item) => ({
          text: item.nombre,
          value: item.id_farmacia,
        }));

        const laboratoriosData = await new Promise((resolve) =>
          this.getLaboratorios(resolve)
        );
        this.opcionesLaboratorio = laboratoriosData.map((item) => ({
          text: item.nombre,
          value: item.id_laboratorio,
        }));

        // Obtener categorías y limpiar datos innecesarios
        const categoriasData = await new Promise((resolve) =>
          this.getCategorias(resolve)
        );

        this.opcionesCategoria = categoriasData.map((item) => ({
          text: item.categoria,
          value: item.id_categoria,
        }));

        const tiposData = await new Promise((resolve) =>
          this.getTiposArticulos(resolve)
        );

        this.opcionesTipo = tiposData.map((item) => ({
          text: item.tipo_articulo_desc,
          value: item.tipo_articulo_codigo,
        }));
      } catch (error) {
        console.error('Error al cargar filtros:', error);
      }
      this.loadDatosFarmacia();
    },

    validateForm() {
      let validado = true;

      // Validar si el tipo es 'C' (Categoría) y los campos son obligatorios
      if (this.itemMod.tipo === 'C') {
        if (!this.itemMod.tipo_articulo_codigo) {
          this.form.tipo_articulo_codigo.hasError = true;
          this.form.tipo_articulo_codigo.errors.push({
            show: true,
            message: 'Seleccione el tipo de artículo',
          });
          validado = false;
        } else {
          this.form.tipo_articulo_codigo.hasError = false;
          this.form.tipo_articulo_codigo.errors = [];
        }

        if (!this.itemMod.id_categoria) {
          this.form.id_categoria.hasError = true;
          this.form.id_categoria.errors.push({
            show: true,
            message: 'Seleccione una categoría',
          });
          validado = false;
        } else {
          this.form.id_categoria.hasError = false;
          this.form.id_categoria.errors = [];
        }

        if (!this.itemMod.id_laboratorio) {
          this.form.id_laboratorio.hasError = true;
          this.form.id_laboratorio.errors.push({
            show: true,
            message: 'Seleccione un laboratorio',
          });
          validado = false;
        } else {
          this.form.id_laboratorio.hasError = false;
          this.form.id_laboratorio.errors = [];
        }
      }

      // Validar si el tipo es 'P' (Producto) y el código de producto es obligatorio
      if (this.itemMod.tipo === 'P' && !this.itemMod.codigo_producto) {
        this.form.codigo_producto.hasError = true;
        this.form.codigo_producto.errors.push({
          show: true,
          message: 'Introduce código del producto',
        });
        validado = false;
      } else {
        this.form.codigo_producto.hasError = false;
        this.form.codigo_producto.errors = [];
      }

      // Validar si el descuento es obligatorio
      if (!this.itemMod.dto) {
        this.form.dto.hasError = true;
        this.form.dto.errors.push({
          show: true,
          message: 'Introduce el descuento',
        });
        validado = false;
      } else {
        this.form.dto.hasError = false;
        this.form.dto.errors = [];
      }

      if (!this.itemMod.id_farmacia) {
        this.form.nombre_farmacia.hasError = true;
        this.form.nombre_farmacia.errors.push({
          show: true,
          message: 'Introduce la farmacia',
        });
        validado = false;
      } else {
        this.form.nombre_farmacia.hasError = false;
        this.form.nombre_farmacia.errors = [];
      }

      if (!this.itemMod.tipo) {
        this.form.tipo.hasError = true;
        this.form.tipo.errors.push({
          show: true,
          message: 'Selecciona un tipo',
        });
        validado = false;
      } else {
        this.form.tipo.hasError = false;
        this.form.tipo.errors = [];
      }

      return validado;
    },

    cleanForm() {
      this.form = {
        descripcion: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        dto: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        codigo_producto: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        id_categoria: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        tipo_articulo_codigo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_farmacia: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        id_laboratorio: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        tipo: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scope>
.form {
  overflow: visible;
}
.form-error-box.min-height {
  min-height: 10px !important;
}
</style>
