<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center pt-5">
      <img
        class="loading-icon"
        src="@/assets/loading.gif"
        alt=""
        style="width: 150px; height: 150px"
      />
    </div>
    <div v-else id="login-page" class="d-flex">
      <div class="left-part d-flex justify-content-center align-items-center">
        <img class="logo" src="@/assets/logo.jpeg" />

        <div class="login-card">
          <span
            v-if="step > 0"
            class="arrow-back-btn icon-arrow-back-outline"
            @click="backTo"
          ></span>
          <div v-for="error in errors" class="form-group error">
            <p>{{ error }}</p>
          </div>
          <div v-if="existeToken == 1">
            <h2 class="font-weight-[600px]">Recupera tu contraseña de</h2>
            <h2 class="mb-4 font-italic font-weight-[600px]">Farmasapiens</h2>

            <div id="step-one">
              <input-box
                v-model="credentials.password"
                label="Contraseña"
                type="password"
              />
            </div>

            <div>
              <input-box
                v-model="credentials.password_repetead"
                label="Repetir Contraseña"
                type="password"
              />
              <b-button class="w-100 mt-3" variant="primary" @click="initLogin">
                Acceder</b-button
              >
            </div>
          </div>
          <div v-else id="expirada">
            <h3>¡Su solicitud de recuperacion ha sido expirada!</h3>
            <b-button
              class="w-100 mt-3"
              variant="primary"
              size="sm"
              @click="irLogin"
            >
              Acceder</b-button
            >
          </div>
        </div>
        <modal-confirmacion
          v-if="showConfirmation"
          title="La contraseña ha sido actualizada correctamente"
          descripcion=" "
          confirm-text="Aceptar"
          :show-button-cancel="showButtonCancel"
          :type-toast-reset-password="typeToastResetPassword"
          :desc-toast-reset-password="descToastResetPassword"
          :show-toast-reset-password="showToastResetPassword"
          @confirm="handleConfirmation"
          @close="closeConfirmation"
        >
        </modal-confirmacion>
      </div>

      <div
        class="right-part d-flex justify-content-center align-items-center vh-100"
      >
        <div class="slider-content">
          <CarouselSingle :arrows="false" :slides="slides" :indicators="false">
            <template #slide="{ slide }">
              <div class="slide">
                <object
                  :data="require(`${slide.src}`)"
                  width="74"
                  height="74"
                ></object>
                <h4>{{ slide.title }}</h4>
                <p>
                  <i> {{ slide.content }} </i>
                </p>
              </div>
            </template>
          </CarouselSingle>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CarouselSingle } from 'frontendcommon';

import auth from '@/api/auth';

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',

  components: { CarouselSingle },
  extends: auth,
  data() {
    return {
      existeToken: 0,
      credentials: {
        password: '',
        password_repetead: '',
      },
      form: {
        password: {
          hasError: false,
          errors: [],
        },
        password_repetead: {
          hasError: false,
          errors: [],
        },
      },
      slides: [
        {
          src: './assets/step-1.svg',
          title: this.$t('login.carousel.title'),
          content: this.$t('login.carousel.description'),
        },
      ],
      showConfirmation: false,
      token: '',
      errors: [],
      success: undefined,
      existeToken: 0,
      showToastResetPassword: false,
      descToastResetPassword: '',
      showButtonCancel: false,
      changePasswordSuccess: false,
      typeToastResetPassword: '',
      confirmationAction: null,
      loading: false,
      step: 0,
    };
  },
  computed: {
    ...mapGetters(['getAccessToken']),
  },
  watch: {
    changePasswordSuccess(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$router.push('/login');
        }, 3000);
      }
    },
  },

  created() {
    this.changePasswordSuccess = false;
    this.getToken();
  },

  methods: {
    ...mapActions(['saveAccessToken']),

    backTo: function () {
      this.step--;
    },
    nextTo: function () {
      this.cleanForm();

      if (this.credentials.password === '') {
        this.form.password.hasError = true;
        this.form.password.errors.push({
          show: true,
          message: 'Contraseña obligatorio',
        });
      } else this.step++;
    },
    handleConfirmation() {
      if (this.confirmationAction) {
        this.confirmationAction();
      } else {
        this.closeConfirmation();
      }
    },
    initLogin: function () {
      this.cleanForm();

      if (this.credentials.password_repetead === '') {
        this.form.password_repetead.hasError = true;
        this.form.password_repetead.errors.push({
          show: true,
          message: 'Contraseña obligatoria',
        });
      } else this.guardarPassword();
    },
    cleanForm: function () {
      this.form = {
        password: {
          hasError: false,
          errors: [],
        },
        password_repetead: {
          hasError: false,
          errors: [],
        },
      };
    },

    guardarPassword: function () {
      this.errors = [];
      this.success = undefined;

      if (this.credentials.password != this.credentials.password_repetead) {
        this.errors.push('Las contraseñas no coinciden');
        return;
      }

      let generatePasswordPromises = new Promise((resolve) => {
        this.savePassword(
          resolve,
          this.email,
          this.credentials.password,
          this.token
        );
      });
      generatePasswordPromises.then((data) => {
        let response = data.data;

        if (response.success == true) {
          this.success = response.message;
          this.changePasswordSuccess = true;
          this.confirmationAction = true;
          this.showConfirmation = true;
        } else {
          this.typeToastResetPassword = 'error';
          this.descToastResetPassword = response.message;
          this.showToastResetPassword = true;
          this.changePasswordSuccess = false;
          this.confirmationAction = this.closeConfirmation;

          this.showConfirmation = true;
        }
      });
    },
    closeConfirmation() {
      this.showConfirmation = false;
    },

    getToken: function () {
      this.token = this.$route.query.token;
      let validateTokenPromise = new Promise((resolve) => {
        this.validateToken(resolve, this.token);
      });
      validateTokenPromise.then((data) => {
        let response = data.data;
        if (response) {
          this.existeToken = true;
        }
      });
    },

    irLogin: function () {
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
#login-page {
  background: #ffffff;

  .left-part {
    flex-basis: 60%;
    width: 60%;
    position: relative;

    .arrow-back-btn {
      display: block;
      margin-bottom: 40px;
      cursor: pointer;
      vertical-align: sub;
      color: var(--fc-brand-03);
      font-size: 24px;
    }

    .logo {
      position: absolute;
      top: 40px;
      left: 55px;
      max-width: 180px;
    }

    .login-card {
      width: 460px;
    }

    .forget-password {
      padding: 25px;
      border: 1px solid var(--fc-brand-01);
    }
  }

  .right-part {
    flex-basis: 40%;
    width: 40%;
    background-color: var(--fc-brand-01);

    .slider-content {
      width: 270px;

      .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        * {
          color: #fff;
          text-align: center;
        }

        h4 {
          margin: 60px 0 10px 0;
        }
      }
    }
  }
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.mt-2 {
  margin-top: 16px;
}
</style>
