<template>
  <div id="categorias">
    <b-row>
      <b-col>
        <h4>{{ title }}</h4>
      </b-col>
      <export-excel
        :header="excelExportColumns"
        :listado="items"
        :filename="'Detalle_Compras_' + type + '.xlsx'"
        variant="link"
      />
    </b-row>
    <DataTableNaf
      :items="items"
      :fields="fields"
      :is-fetching="isFetching"
      :per-page="5"
      :grid-cols="gridCols"
      :is-compact="isCompactTable"
      compact-fields="nombre"
      @onRowClick="handleClickRow"
    >
      <template #cell(nombre)="{ item }">
        <span> {{ item.nombre }} </span>
      </template>
      <template #cell(factPactado)="{ item }">
        <span> {{ $n(parseFloat(item.factPactado), 'currency') }} </span>
      </template>
      <template #cell(udsPactado)="{ item }">
        <span> {{ $n(item.udsPactado, 'unidades') }}</span>
      </template>
      <template #cell(dtoPactado)="{ item }">
        <span> {{ $n(parseFloat(item.dtoPactado), 'currency') }} </span>
      </template>
      <template #cell(factNoPactado)="{ item }">
        <span> {{ $n(parseFloat(item.factNoPactado), 'currency') }} </span>
      </template>
      <template #cell(udsNoPactado)="{ item }">
        <span> {{ $n(item.udsNoPactado, 'unidades') }}</span>
      </template>
      <template #cell(porcProvPactado)="{ item }">
        <span :style="{ color: getCumplimientoColor(item.porcProvPactado) }">
          {{ $n(item.porcProvPactado, 'percentDecimal') }}
        </span>
      </template>
    </DataTableNaf>
  </div>
</template>

<script>
export default {
  name: 'CategoriasDetalleCompras',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Título por defecto',
    },
    uniquekey: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'listado',
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCompactTable: false,
      fields: [],
      fieldsDato: [
        {
          key: 'nombre',
          sortable: true,
          label: 'Nombre',
          header: 'Nombre',
          tooltip: '',
        },
        {
          key: 'factPactado',
          sortable: true,
          label: 'Compras proveedor pactado',
          header: 'Compras proveedor pactado',
          tooltip: '',
        },
        {
          key: 'udsPactado',
          sortable: true,
          label: 'Uds proveedor pactado',
          header: 'Uds proveedor pactado',
          tooltip: '',
        },
        {
          key: 'dtoPactado',
          sortable: true,
          label: 'Dto proveedor pactado',
          header: 'Dto proveedor pactado',
          tooltip: '',
        },
        {
          key: 'factNoPactado',
          sortable: true,
          label: 'Compras proveedor no pactado',
          header: 'Compras proveedor no pactado',
          tooltip: '',
        },
        {
          key: 'udsNoPactado',
          sortable: true,
          label: 'Uds proveedor no pactado',
          header: 'Uds proveedor no pactado',
          tooltip: '',
        },
        {
          key: 'porcProvPactado',
          sortable: true,
          label: 'Compras proveedor pactado',
          header: 'Compras proveedor pactado',
          tooltip: '',
        },
      ],
      excelExportColumns: [
        {
          key: 'nombre',
          sortable: true,
          label: 'Nombre',
          header: 'Nombre',
          tooltip: '',
        },
        {
          key: 'factPactado',
          sortable: true,
          label: 'Compras proveedor pactado',
        },
        {
          key: 'udsPactado',
          sortable: true,
          label: 'Uds proveedor pactado',
        },
        {
          key: 'dtoPactado',
          sortable: true,
          label: 'Dto proveedor pactado',
        },
        {
          key: 'factNoPactado',
          sortable: true,
          label: 'Compras proveedor no pactado',
        },
        {
          key: 'udsNoPactado',
          sortable: true,
          label: 'Uds proveedor no pactado',
        },
        {
          key: 'porcProvPactado',
          sortable: true,
          label: 'Compras proveedor pactado',
        },
      ],
      gridCols: '1.5fr 1fr 1fr 1fr 1fr 1fr 1fr',
    };
  },
  mounted() {
    this.isCompactTable = window.innerWidth < 992;

    this.fields = this.fieldsDato;
    window.addEventListener('resize', this.handleUpdateScreenSize);
  },
  methods: {
    handleClickRow: function (row) {
      this.$emit('rowClick', row);
    },
    handleUpdateScreenSize: function () {
      this.isCompactTable = window.innerWidth < 992;
    },
    getCumplimientoColor(percent) {
      if (percent >= 0.8) {
        return '#75B84E'; // Verde
      } else if (percent < 0.8 && percent >= 0.7) {
        return '#E3B428'; // Amarillo
      } else {
        return '#E72D51'; // Rojo
      }
    },
  },
};
</script>

<style scoped lang="scss">
#categorias {
  h4 {
    span {
      color: var(--fc-brand-01);
      text-transform: lowercase;
    }
  }
  @media screen and (max-width: 992px) {
    ::v-deep .compact-fields {
      display: flex;
      flex-wrap: wrap;
    }
    ::v-deep .grid {
      grid-template-columns: none !important;
      justify-content: start;
    }
  }
}
</style>
