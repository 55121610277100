<template>
  <div class="input-solo productos-selector">
    <div>
      <InputSolo v-model="text" placeholder="Código">
        <template #left>
          <span class="icon-search-outline"></span>
        </template>
        <template #right>
          <span
            class="icon-close-outline clickable-icon"
            @click="clearSearch"
          ></span>
        </template>
      </InputSolo>
    </div>
  </div>
</template>

<script>
import InputSolo from 'frontendcommon/src/components/design-system/form/InputSolo.vue';

export default {
  name: 'CodeInput',
  components: {
    InputSolo,
  },
  extends: InputSolo,

  data() {
    return {
      text: '',
    };
  },
  watch: {
    text(newValue) {
      if (isNaN(newValue)) {
        this.$nextTick(() => {
          this.text = '';
        });
      } else {
        this.emitCode();
      }
    },
  },

  mounted() {},
  methods: {
    clearSearch() {
      this.text = '';
    },
    emitCode() {
      this.$emit('update_filters', this.text);
    },
  },
};
</script>

<style>
.input-solo {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap !important;
  max-height: 45px;

  .center {
    width: 70px;
    align-content: center;

    input {
      align-content: center;
      padding: 0 !important;
    }
  }
}
.clickable-icon {
  cursor: pointer;
}
</style>
