<template>
  <modal @hide="$emit('close')">
    <template #header>
      <div>
        <h3 v-if="!esNuevo">{{ titleEditar }}</h3>
        <h3 v-else>{{ titleCrear }}</h3>
      </div>
    </template>
    <template>
      <div class="form">
        <card-form :style="combinedStyles" title="Informacion básica">
          <template>
            <card-form-item title="Nombre">
              <template #description>
                <input-box
                  v-model="itemMod.nombre"
                  v-bind="form.nombre"
                  :errors="form.nombre.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
            <card-form-item title="Proveedor sellout">
              <template #description>
                <div>
                  <select-box
                    v-model="itemMod.id_proveedor_sellout"
                    label="Proveedor-sellout"
                    :filter="true"
                    :options="opcionesProveedorSellout"
                    v-bind="form.nombre_proveedor_sellout"
                    :errors="form.nombre_proveedor_sellout.errors"
                    :has-validation="true"
                  />
                </div>
              </template>
            </card-form-item>
            <card-form-item title="URL fedicom">
              <template #description>
                <input-box
                  v-model="itemMod.url_fedicom"
                  v-bind="form.url_fedicom"
                  :errors="form.url_fedicom.errors"
                  :has-validation="true"
                />
              </template>
            </card-form-item>
          </template>
        </card-form>
        <card-form
          :style="combinedStyles"
          title="Tipos pedido a tener en cuenta el cálculo beneficio"
        >
          <card-form-item title="Código tipo pedido">
            <template #description>
              <input-box v-model="newElement.codigo_tipo_pedido" />
            </template>
          </card-form-item>
          <card-form-item title="Descripción tipo pedido">
            <template #description>
              <input-box v-model="newElement.descripcion_tipo_pedido" />
            </template>
          </card-form-item>
          <div class="p-3">
            <b-button variant="primary" @click="addElement">
              Añadir tipo pedido
            </b-button>
          </div>

          <DataTableNaf :fields="columns" :items="tiposPedido" :per-page="10">
            <template #cell(codigo_tipo_pedido)="{ item }">
              <span>{{ item.codigo_tipo_pedido }}</span>
            </template>
            <template #cell(descripcion_tipo_pedido)="{ item }">
              <span>{{ item.descripcion_tipo_pedido }}</span>
            </template>
            <template #cell(icons)="{ item }">
              <div class="d-flex justify-content-between">
                <div>
                  <span
                    class="icon icon-trash-outline"
                    @click="borrarTipoDescuento(item)"
                  ></span>
                </div>
              </div>
            </template>
          </DataTableNaf>
        </card-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex" :style="colorStyles">
        <div class="p-3">
          <b-button variant="outline-primary" @click="$emit('close')">{{
            $t('button.cancel')
          }}</b-button>
        </div>
        <div class="p-3">
          <b-button variant="primary" @click="guardar">{{
            $t('button.save')
          }}</b-button>
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
import proveedores from '@/api/proveedores.api';
import colors from '@/mixins/colors.js';

export default {
  name: 'ModalProveedores',
  components: {},
  extends: proveedores,
  mixins: [colors],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    esNuevo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      titleEditar: 'Editar',
      titleCrear: 'Crear',
      itemMod: {},
      tiposPedido: [],
      newElement: {
        codigo_tipo_pedido: '',
        descripcion_tipo_pedido: '',
      },
      opcionesProveedorSellout: [],
      columns: [
        {
          key: 'codigo_tipo_pedido',
          sortable: true,
          label: 'Código',
        },
        {
          key: 'descripcion_tipo_pedido',
          sortable: true,
          label: 'Descripción',
        },
        {
          key: 'icons',
          sortable: false,
          label: '',
          class: 'justify-content-end',
        },
      ],
      form: {
        nombre: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_proveedor_sellout: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        url_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      },
      esEstandar: true,
    };
  },
  watch: {
    item: {
      handler: function () {
        if (Object.keys(this.item).length > 0) {
          this.cargarDatosIniciales();
        }
        this.loadFiltros();
      },
      immediate: true,
    },
  },
  methods: {
    cargarDatosIniciales() {
      this.itemMod = { ...this.item };
      this.tiposPedido = this.item.tipos_pedidos;
    },
    actualizarCheck: function (data) {
      console.log(data);
    },

    guardar() {
      this.cleanForm();
      if (!this.validateForm()) {
        return;
      }
      this.itemMod = {
        ...this.itemMod,
      };
      this.itemMod.tipos_pedidos = this.tiposPedido;
      this.tiposPedido = [];
      if (this.esNuevo) {
        let createPromise = new Promise((resolve) => {
          this.createProveedor(resolve, this.itemMod);
        });
        createPromise.then(() => {
          this.$emit('save');
          this.$emit('close');
        });
      } else {
        let updatePromise = new Promise((resolve) => {
          this.updateProveedor(
            resolve,
            this.itemMod,
            this.itemMod.id_proveedor
          );
        });
        updatePromise.then(() => {
          this.$emit('save');
          this.$emit('close');
        });
      }
    },
    loadFiltros: function () {
      this.loading = true;
      let proveedoresSelloutPromise = new Promise((resolve) => {
        this.getProveedoresSellout(resolve);
      });
      Promise.all([proveedoresSelloutPromise]).then(
        ([proveedoresSelloutData]) => {
          console.log('proveedores sellout', proveedoresSelloutData);
          this.opcionesProveedorSellout = proveedoresSelloutData.map((item) => {
            item.text = item.nombre;
            item.value = item.id_proveedor_sellout;
            return item;
          });
          this.loading = false;
        }
      );
    },
    // Función para añadir un nuevo elemento al listado
    addElement() {
      if (
        this.newElement.codigo_tipo_pedido != '' &&
        this.newElement.descripcion_tipo_pedido != ''
      ) {
        this.tiposPedido.push({ ...this.newElement });
        this.newElement.codigo_tipo_pedido = '';
        this.newElement.descripcion_tipo_pedido = '';
      }
    },
    removeElement(index) {
      this.tiposPedido.splice(index, 1);
    },
    validateForm() {
      let validado = true;

      if (!this.itemMod.nombre) {
        this.form.nombre.hasError = true;
        this.form.nombre.errors.push({
          show: true,
          message: 'Introduce nombre',
        });
        validado = false;
      } else {
        this.form.nombre.hasError = false;
        this.form.nombre.errors = [];
      }

      if (!this.itemMod.id_proveedor_sellout) {
        this.form.nombre_proveedor_sellout.hasError = true;
        this.form.nombre_proveedor_sellout.errors.push({
          show: true,
          message: 'Selecciona un proveedor',
        });
        validado = false;
      } else {
        this.form.nombre_proveedor_sellout.hasError = false;
        this.form.nombre_proveedor_sellout.errors = [];
      }

      if (!this.itemMod.url_fedicom) {
        this.form.url_fedicom.hasError = true;
        this.form.url_fedicom.errors.push({
          show: true,
          message: 'Introduce la url',
        });
        validado = false;
      } else {
        this.form.url_fedicom.hasError = false;
        this.form.url_fedicom.errors = [];
      }

      if (!this.itemMod.descripcion === '') {
        this.form.descripcion.hasError = true;
        this.form.descripcion.hasValidation = true;
        this.form.descripcion.errors.push({
          show: true,
          message: this.$t('error.obligatorio.default'),
        });
        validado = false;
      }
      return validado;
    },
    cleanForm: function () {
      this.form = {
        descripcion: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        nombre_proveedor_sellout: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
        url_fedicom: {
          hasError: false,
          errors: [],
          hasValidation: false,
          'text-center': true,
        },
      };
    },
    borrarTipoDescuento: function (itemBorrar) {
      this.tiposPedido = this.tiposPedido.filter(
        (item) => item.codigo_tipo_pedido !== itemBorrar.codigo_tipo_pedido
      );
    },
  },
};
</script>

<style lang="scss" scope>
.form {
  overflow: visible;
}
</style>
