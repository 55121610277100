export default {
  namespaced: true,
  state: {
    filtros: {
      proveedores: [],
      laboratorios: [],
      tipos: [],
      categorias: [],
      subfamilias: [],
    },
  },
  mutations: {
    setFiltros(state, { filtro, items }) {
      state.filtros[filtro] = items;
    },
    resetFiltros(state) {
      state.filtros = {
        proveedores: [],
        laboratorios: [],
        tipos: [],
        categorias: [],
        subfamilias: [],
      };
    },
  },
  actions: {
    updateFiltros({ commit }, { filtro, items }) {
      commit('setFiltros', { filtro, items });
    },
    clearFiltros({ commit }) {
      commit('resetFiltros');
    },
  },
  getters: {
    getFiltros: (state) => state.filtros,
  },
};
