<template>
  <div v-if="counterKey > 0" id="app" :style="themeColors">
    <router-view> </router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import auth from '@/api/auth';
export default {
  name: 'App',
  extends: auth,
  data() {
    return {
      counterKey: 0,
      brand01: '#319EDD',
      brand01Dark: '#74CCFF',
      brand01Medium: '#9BDAFF',
      brand01Soft: '#EBF8FF',
      brand02: '#38B6FF',
      brand03: '#3D3F49',
      brand04: '#FFFFFF',
      warning: '#E3B428',
      danger: '#E72D51',
      green: '#75B84E',
      yellow: '#E3B428',
      red: '#B45D55',
    };
  },
  computed: {
    themeColors: function () {
      return {
        '--fc-brand-01': this.brand01,
        '--fc-brand-01-dark': this.brand01Dark,
        '--fc-brand-01-medium': this.brand01Medium,
        '--fc-brand-01-soft': this.brand01Soft,
        '--fc-brand-02': this.brand02,
        '--fc-brand-03': this.brand03,
        '--fc-brand-04': this.brand04,
        '--fc-warning': this.warning,
        '--fc-danger': this.danger,
      };
    },
  },
  created() {
    if (
      !this.$route.path.includes('login') &&
      !this.$route.path.includes('resetpassword')
    ) {
      this.checkSessionStatus();
    } else {
      this.counterKey += 1;
    }

    window.colors = {
      brand01: this.brand01,
      brand01Dark: this.brand01Dark,
      brand01Medium: this.brand01Medium,
      brand01Soft: this.brand01Soft,
      brand02: this.brand02,
      brand03: this.brand03,
      brand04: this.brand04,
      success: '#75B84E',
      warning: this.warning,
      danger: this.danger,
      dayDarkGrey: '#737373',
    };
  },
  methods: {
    ...mapActions(['saveAccessToken', 'saveUsername', 'saveUser']),
    checkSessionStatus: function () {
      let sessionStatusPromise = new Promise((resolve) => {
        this.sessionStatus(resolve);
      });
      sessionStatusPromise.then((data) => {
        let user = data.user;
        let farmaciaSeleccionada = data.user.farmaciaseleccionada;
        if (user.length === 0) {
          console.log('usuario no encontrado');
        } else {
          this.$store.commit(
            'farmacias/setSelectedFarmacia',
            farmaciaSeleccionada?.id_farmacia
          );
          this.saveUser(user);
          this.counterKey += 1;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import 'frontendcommon/dist/frontendcommon.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* {
  font-family: 'Montserrat', sans-serif !important;
  box-sizing: border-box;
}
template {
  background-color: #f7f8fc;
}

.widget-content {
  min-height: auto;
}

.icon {
  cursor: pointer;
}
</style>
