import store from '../store';
import httpClient from './httpClient';

const PATH = '/farmaciaDescuento/';

export default {
  methods: {
    getDescuentos(resolve, request) {
      httpClient
        .post(PATH + 'index', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showFarmaciaDescuento(resolve, id) {
      httpClient
        .get(PATH + 'show/' + id)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createFarmaciaDescuento(resolve, request) {
      httpClient
        .post(PATH + 'create', request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateFarmaciaDescuento(resolve, request, id) {
      httpClient
        .post(PATH + 'update/' + id, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFarmaciaDescuento(resolve, request, id) {
      httpClient
        .delete(PATH + 'destroy/' + id, request)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async uploadCSVFarmaciaDescuento(file) {
      const formData = new FormData();
      formData.append('file', file);

      const accessToken = store.getters.getAccessToken;
      const baseUrl = process.env.VUE_APP_ENDPOINT;
      const url = `${baseUrl}${PATH}upload`;

      const headers = {
        accept: 'application/json',
        Authorization: `Bearer ${accessToken}`, // Usamos el token del store
      };

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers,
          body: formData,
        });

        const data = await response.json();

        if (response.ok) {
          return data;
        } else {
          console.error('Error al cargar el archivo:', data);
          throw new Error(data.message || 'Error al cargar el archivo');
        }
      } catch (error) {
        console.error('Hubo un problema con la solicitud:', error);
        throw error;
      }
    },
  },
};
