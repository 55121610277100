<template>
  <modal
    :title="title"
    :hide-buttons="true"
    :show-x="true"
    :response="response"
    @hide="$emit('close')"
  >
    <template #header>
      <div v-if="responseError === '' && response.length === 0">
        <h3>El archivo se ha importado correctamente</h3>
      </div>
      <div v-else><h3>Error al importar el archivo</h3></div>
    </template>

    <template>
      <div v-if="response && response.length > 0">
        <div class="mb-2">Se han producido los siguientes errores:</div>
        <DataTableNaf
          :fields="columns"
          :items="transformedResponse"
          :per-page="5"
          grid-cols="1fr 3fr"
        >
          <template #cell(linea)="{ item }">
            <span>{{ item.linea }}</span>
          </template>
          <template #cell(error)="{ item }">
            <span>{{ item.error }}</span>
          </template>
        </DataTableNaf>
      </div>
      <div v-else>
        <div v-if="responseError === ''">
          Se ha importado correctamente el archivo
        </div>
        <div v-else>
          Por favor, revise que todos los campos del archivo son correctos y que
          cada línea contenga la farmacia, el artículo y el descuento
          correspondiente.
        </div>
      </div>
    </template>

    <template #footer>
      <div class="d-flex justify-content-end" :style="colorStyles">
        <b-button class="ml-3 cta" @click="$emit('confirm')">{{
          confirmText
        }}</b-button>
      </div>
    </template>

    <template #bottom>
      <div
        v-if="showButtonCancel"
        class="d-flex justify-content-center"
        :style="colorStyles"
      >
        <b-button class="ml-3 cta" @click="$emit('confirm')">{{
          confirmText
        }}</b-button>
      </div>
      <div v-else class="d-flex justify-content-center" :style="colorStyles">
        <b-button class="ml-3 cta" @click="$emit('close')">{{
          confirmText
        }}</b-button>
      </div>
    </template>
  </modal>
</template>

<script>
import colors from '@/mixins/colors.js';
export default {
  name: 'ModalUploadCSV',
  mixins: [colors],
  props: {
    showConfirmation: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Importar descuentos por artículo',
    },
    response: {
      type: Array,
      default: () => [],
    },
    showToastResetPassword: {
      type: Boolean,
      default: false,
    },
    descToastResetPassword: {
      type: String,
      default: '',
    },
    typeToastResetPassword: {
      type: String,
      default: 'success',
    },
    showButtonCancel: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: 'Aceptar',
    },
    responseError: {
      type: String,
      default: '',
    },
  },
  computed: {
    columns() {
      return [
        { key: 'linea', label: 'Linea csv', sortable: true },
        { key: 'error', label: 'Mensaje de error', sortable: false },
      ];
    },
    transformedResponse() {
      return this.response.map((item) => {
        if (item.error.includes('no controlado')) {
          return { ...item, error: 'Formato incorrecto' };
        }
        return item;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
@import 'frontendcommon/src/scss/config';
.custom-calendar {
  border-width: 0 !important;
}
::v-deep .vc-header {
  margin-bottom: 30px;
}
.header-button {
  font-size: 20px;
  color: $dark-grey;
}
.header-title {
  @include button();
  color: $dark-grey;
}
::v-deep .vc-weekday {
  @include h8();
  color: $medium-grey;
}
::v-deep .vc-day {
  position: relative;
  border: 1px solid $light-grey;
  padding-top: 90%;
}
::v-deep .is-today .day-header span {
  background: $brand-03;
  color: white;
}

.hide-arrows ::v-deep .vc-arrow {
  display: none;
}
::v-deep .vc-grid-container {
  overflow: visible;
}
</style>
