<template>
  <BaseView :title="fromTable">
    <template #content>
      <filtros-detalle :genericos="genericos" />
      <CategoriasDetalleCompras
        :uniquekey="proveedorKeyArray"
        title="Por proveedor"
        :items="proveedorData"
        type="Proveedor"
        class="mt-5"
        :is-fetching="isFetchingProveedor"
        @rowClick="clickProveedor"
      />
      <CategoriasDetalleCompras
        :uniquekey="laboratorioKeyArray"
        title="Por laboratorio"
        type="Laboratorio"
        :items="laboratoriosData"
        class="mt-5"
        :is-fetching="isFetchingLaboratorio"
        @rowClick="clickLaboratorio"
      />
      <CategoriasDetalleCompras
        v-if="!genericos"
        :uniquekey="tipoArticuloKeyArray"
        title="Por tipo de artículo"
        type="TipoDeArtículo"
        :items="tipoArticulosData"
        class="mt-5"
        :is-fetching="isFetchingTipoArticulo"
        @rowClick="clickTipoArticulo"
      />
      <CategoriasDetalleCompras
        v-if="!genericos"
        :uniquekey="categoriaKeyArray"
        title="Por categoría"
        type="Categoría"
        :items="categoriasData"
        class="mt-5"
        :is-fetching="isFetchingCategoria"
        @rowClick="clickCategoria"
      />
      <CategoriasDetalleCompras
        :uniquekey="subfamiliaKeyArray"
        title="Por subfamilia"
        type="Subfamilia"
        :items="subfamiliasData"
        class="mt-5"
        :is-fetching="isFetchingSubfamilia"
        @rowClick="clickSubFamilia"
      />
      <CategoriasDetalleCompras
        :uniquekey="articuloKeyArray"
        title="Por artículo"
        type="Artículo"
        :items="articulosData"
        class="mt-5"
        :is-fetching="isFetchingArticulo"
        @rowClick="clickArticulo"
      />
    </template>
  </BaseView>
</template>

<script>
import { mapGetters } from 'vuex';
import comprasApi from '../../api/compras';
import CategoriasDetalleCompras from '../../components/compras/resumen/mixins/CategoriasDetalleCompras.vue';
import FiltrosDetalle from '../../components/compras/resumen/FiltrosDetalle.vue';
import { mapState } from 'vuex';

export default {
  name: 'Resumen',
  components: {
    CategoriasDetalleCompras,
    FiltrosDetalle,
  },
  extends: comprasApi,
  data() {
    return {
      type: [],
      filterOpened: null,
      loadingFilterCategorizacion: true,
      categorias: [],
      subcategorias: [],
      subfamilias: [],
      tipoVenta: 'TOTAL',
      laboratorio: null,
      proveedor: null,
      tipoArticulo: null,
      categoria: null,
      subfamilia: null,
      key: 1,
      storeFarmacia: null,
      proveedorKeyArray: ['Proveedor' + this.key],
      laboratorioKeyArray: ['Laboratorios' + this.key],
      tipoArticuloKeyArray: ['TipoArticulo' + this.key],
      categoriaKeyArray: ['Categoria' + this.key],
      subfamiliaKeyArray: ['Subfamilia' + this.key],
      articuloKeyArray: ['articulo' + this.key],
      proveedorData: [],
      categoriasData: [],
      subfamiliasData: [],
      laboratoriosData: [],
      articulosData: [],
      tipoArticulosData: [],
      isFetchingProveedor: false,
      isFetchingLaboratorio: false,
      isFetchingTipoArticulo: false,
      isFetchingCategoria: false,
      isFetchingSubfamilia: false,
      isFetchingArticulo: false,
      fromTable: '',
      periodo: '',
      genericos: false,
    };
  },
  computed: {
    ...mapGetters('farmacias', ['getSelectedFarmacia']),
    ...mapState('filters', ['filtros']),
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        this.callApi();
      },
    },
  },
  mounted() {
    const redirectData = JSON.parse(sessionStorage.getItem('redirectData'));

    this.fromTable = redirectData.tableName;
    this.periodo = redirectData.periodo;
    this.genericos = redirectData.genericos;

    this.clearFiltros();
    this.callApi();
  },

  methods: {
    clearFiltros() {
      this.$store.dispatch('filters/clearFiltros');
    },
    clickProveedor(value) {
      this.proveedor = value.codigo;
      this.$store.dispatch('filters/updateFiltros', {
        filtro: 'proveedores',
        items: [value],
      });
      this.callApi();
    },
    clickLaboratorio(value) {
      this.laboratorio = value.codigo;
      this.$store.dispatch('filters/updateFiltros', {
        filtro: 'laboratorios',
        items: [value],
      });
      this.callApi();
    },
    clickTipoArticulo(value) {
      this.tipoArticulo = value.codigo;
      this.$store.dispatch('filters/updateFiltros', {
        filtro: 'tipos',
        items: [value],
      });
      this.callApi();
    },
    clickCategoria(value) {
      this.categoria = value.codigo;
      this.$store.dispatch('filters/updateFiltros', {
        filtro: 'categorias',
        items: [value],
      });
      this.callApi();
    },
    clickSubFamilia(value) {
      this.subfamilia = value.codigo;
      this.$store.dispatch('filters/updateFiltros', {
        filtro: 'subfamilias',
        items: [value],
      });
      this.callApi();
    },
    clickArticulo(value) {
      this.articulo = value.codigo;
      this.callApi();
    },
    setFilterOpened(label) {
      this.filterOpened = label;
    },
    selectedFilterSellData: function (value) {
      this.tipoVenta = value.value;
    },
    filterArrayOptions: function (array, condition, value) {
      let include = array.includes(value);
      if (condition && !include) array.push(value);
      else if (!condition)
        array = array.filter(function (item) {
          return item !== value;
        });
      return array;
    },
    callApi: function () {
      this.key++;
      let catFilter = {};

      if (this.filtros.proveedores.length > 0) {
        catFilter.proveedores = this.filtros.proveedores.map((proveedor) => {
          const proveedorFilter = proveedor.value
            ? String(proveedor.value)
            : String(proveedor.codigo);

          return proveedorFilter;
        });
      }

      if (this.filtros.laboratorios.length > 0) {
        catFilter.laboratorios = this.filtros.laboratorios.map(
          (laboratorio) => {
            const laboratorioFilter = laboratorio.value
              ? String(laboratorio.value)
              : String(laboratorio.codigo);

            return laboratorioFilter;
          }
        );
      }

      if (this.filtros.tipos.length > 0) {
        catFilter.tipos = this.filtros.tipos.map((tipo) => {
          const tipoFilter = tipo.value
            ? String(tipo.value)
            : String(tipo.codigo);

          return tipoFilter;
        });
      }

      if (this.filtros.categorias.length > 0) {
        catFilter.categorias = this.filtros.categorias.map((categoria) => {
          const tipoCategoria = categoria.value
            ? String(categoria.value)
            : String(categoria.codigo);

          return tipoCategoria;
        });
      }

      if (this.filtros.subfamilias.length > 0) {
        catFilter.subfamilias = this.filtros.subfamilias.map((subfamilia) => {
          const tipoSubfamilias = subfamilia.value
            ? String(subfamilia.value)
            : String(subfamilia.codigo);

          return tipoSubfamilias;
        });
      }

      this.isFetchingProveedor = true;
      this.isFetchingLaboratorio = true;
      this.isFetchingTipoArticulo = true;
      this.isFetchingCategoria = true;
      this.isFetchingSubfamilia = true;
      this.isFetchingArticulo = true;

      let proveedorPromise = new Promise((resolve) => {
        if (this.filtros.proveedores.length === 0) {
          delete catFilter.proveedores;
        }
        this.proveedorData = [];
        this.getComprasDetalleCategorizacion(
          resolve,
          catFilter,
          'proveedor',
          this.periodo,
          this.genericos
        );
      });
      proveedorPromise
        .then((data) => {
          this.proveedorData = data;
        })
        .finally(() => {
          this.isFetchingProveedor = false;
        });

      let laboratorioPromise = new Promise((resolve) => {
        if (this.filtros.laboratorios.length === 0) {
          delete catFilter.laboratorios;
        }
        this.laboratoriosData = [];

        this.getComprasDetalleCategorizacion(
          resolve,
          catFilter,
          'laboratorio',
          this.periodo,
          this.genericos
        );
      });
      laboratorioPromise
        .then((data) => {
          this.laboratoriosData = data;
        })
        .finally(() => {
          this.isFetchingLaboratorio = false;
        });

      let tipoArticuloPromise = new Promise((resolve) => {
        if (this.filtros.tipos.length === 0) {
          delete catFilter.tipos;
        }
        this.tipoArticulosData = [];
        this.getComprasDetalleCategorizacion(
          resolve,
          catFilter,
          'tipo_articulo',
          this.periodo,
          this.genericos
        );
      });
      tipoArticuloPromise
        .then((data) => {
          this.tipoArticulosData = data;
        })
        .finally(() => {
          this.isFetchingTipoArticulo = false;
        });

      let categoriasPromise = new Promise((resolve) => {
        if (this.filtros.categorias.length === 0) {
          delete catFilter.categorias;
        }
        this.categoriasData = [];
        this.getComprasDetalleCategorizacion(
          resolve,
          catFilter,
          'categoria',
          this.periodo,
          this.genericos
        );
      });
      categoriasPromise
        .then((data) => {
          this.categoriasData = data;
        })
        .finally(() => {
          this.isFetchingCategoria = false;
        });

      let subfamiliasPromise = new Promise((resolve) => {
        if (this.filtros.subfamilias.length === 0) {
          delete catFilter.subfamilias;
        }
        this.subfamiliasData = [];
        this.getComprasDetalleCategorizacion(
          resolve,
          catFilter,
          'subfamilia',
          this.periodo,
          this.genericos
        );
      });
      subfamiliasPromise
        .then((data) => {
          this.subfamiliasData = data;
        })
        .finally(() => {
          this.isFetchingSubfamilia = false;
        });

      let articulosPromise = new Promise((resolve) => {
        this.articulosData = [];
        this.getComprasDetalleCategorizacion(
          resolve,
          catFilter,
          'articulo',
          this.periodo,
          this.genericos
        );
      });
      articulosPromise
        .then((data) => {
          this.articulosData = data;
        })
        .finally(() => {
          this.isFetchingArticulo = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#ventas-detail {
  @media screen and (max-width: 992px) {
    .search-container {
      display: none !important;
    }
  }

  .ventas-detail-selectors {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
  .ventas-detail-selectors-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .ventas-detail-filter-button {
    border-radius: 5px;
    height: 45px;
  }
}
</style>
