<template>
  <BaseView title="Control de farmacias">
    <template #content>
      <div class="custom-radio">
        <label>
          <input v-model="isAcumulado" type="radio" value="true" />
          Mostrar por acumulado
        </label>

        <label>
          <input v-model="isAcumulado" type="radio" value="false" />
          Mostrar por útlimo mes cerrado
        </label>
      </div>

      <!-- Tabla Acumulado -->
      <TablaControlFarmacias
        v-if="isAcumulado === 'true'"
        ref="tablaAcumulado"
        :uniquekey="datosComprasAcumuladoKey"
        title="Acumulado"
        :items="datosComprasAcumulado"
        type="Acumulado"
        class="mt-5"
        :is-fetching="isFetching"
      />

      <!-- Tabla Mes -->
      <TablaControlFarmacias
        v-if="isAcumulado === 'false'"
        ref="tablaMes"
        :uniquekey="datosComprasMesKey"
        title="Último mes cerrado"
        :items="datosComprasMes"
        type="Mes"
        class="mt-5"
        :is-fetching="isFetching"
      />
    </template>
  </BaseView>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import homeApi from '../../../api/home.api';
import TablaControlFarmacias from '../../../components/controlFarmacias/TablaDatosControlFarmacias.vue';

export default {
  name: 'ControlFarmacias',
  components: {
    TablaControlFarmacias,
  },
  extends: homeApi,
  data() {
    return {
      datosComprasAcumulado: [],
      datosComprasMes: [],
      key: 1,
      datosComprasAcumuladoKey: ['datosComprasAcumulado' + this.key],
      datosComprasMesKey: ['datosCompras' + this.key],
      isFetching: false,
      isAcumulado: 'true', // Control de la tabla seleccionada
    };
  },
  computed: {
    ...mapGetters('farmacias', ['getSelectedFarmacia']),
    ...mapState('filters', ['filtros']),
  },
  mounted() {
    this.callApi();
  },
  methods: {
    callApi() {
      this.key++;
      this.isFetching = true;
      const datosControlFarmaciasPromise = new Promise((resolve) => {
        this.getDatosControlFarmaciasAdmin(resolve, this.request);
      });
      datosControlFarmaciasPromise.then((data) => {
        this.datosComprasAcumulado = data.acumulado;
        this.datosComprasMes = data.mes;
        this.isFetching = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-radio {
  display: flex;
  gap: 40px;
  margin-left: 10px;

  label {
    font-size: 16px;
    display: flex;
  }

  input[type='radio'] {
    margin-right: 5px;
    transform: scale(1.2);
  }
}
</style>
