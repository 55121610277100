<template>
  <table :class="classes">
    <thead v-if="!hideHeader">
      <tr class="grid" :style="{ 'grid-template-columns': styleGrid }">
        <th
          v-if="isSelectable && multiple && !isFetching"
          class="header-fields selector-cell"
        >
          <slot name="checkbox-all" v-bind="{ isAllSelected, toggleAll }">
            <check-box
              :value="isAllSelected"
              :type="checkboxType"
              @click="toggleAll"
            />
          </slot>
        </th>

        <th
          v-for="header in filteredCompactFields"
          :key="header.key"
          class="header-fields"
          :class="header.class"
        >
          <tooltip
            v-if="header.tooltip"
            position="bottom"
            show-event="hover"
            class="tooltip-header"
          >
            <span
              v-if="header.tooltip"
              :ref="`tp-${header.key}`"
              class="icon-info-outline tooltip-header-icon"
            />

            <template #content>
              <span class="tooltip-header-text">{{ header.tooltip }}</span>
            </template>
          </tooltip>

          <span class="header-field-text">
            {{ header.label }}

            <template v-if="header.sortable">
              <span
                v-if="sortByController === header.key && order === 'desc'"
                class="icon-arrow-ios-upward-outline table-sort-icon active"
                @click="setSort(header.key, 'asc')"
              ></span>

              <span
                v-else
                :class="sortByController === header.key ? 'active' : ''"
                class="icon-arrow-ios-downward-outline table-sort-icon"
                @click="setSort(header.key, 'desc')"
              ></span>
            </template>
          </span>
        </th>
      </tr>
    </thead>

    <tbody v-if="!isFetching" class="custom-scrollbar-small">
      <template v-if="!isCompact">
        <tr
          v-for="(row, j) in paginatedItems"
          :key="j"
          :style="{ 'grid-template-columns': styleGrid }"
          :class="setRowClasses(row.original)"
          @click="onRowClick(row.original, $event)"
        >
          <td v-if="isSelectable" class="selector-cell">
            <slot name="checkbox" v-bind="rowSlotScope(row)">
              <check-box
                :value="isSelected(row.original)"
                :type="checkboxType"
                @click="toggle(row.index)"
              />
            </slot>
          </td>

          <td
            v-for="header in filteredCompactFields"
            :id="header.key"
            :key="header.key + j"
            :class="header.class"
          >
            <slot
              :name="'cell(' + header.key + ')'"
              v-bind="{
                ...row,

                item: row.original,

                value: row.computed[header.key],
              }"
            >
              {{ row.computed[header.key] }}
            </slot>
          </td>
        </tr>
      </template>

      <template v-else>
        <tr
          v-for="(row, j) in paginatedItems"
          :key="j"
          :style="{ 'grid-template-columns': styleGrid }"
          :class="setRowClasses(row.original)"
          @click="openCompact(j)"
        >
          <td v-if="isSelectable && !isFetching" class="selector-cell">
            <slot name="checkbox" v-bind="rowSlotScope(row)">
              <check-box
                :value="isSelected(row.original)"
                :type="checkboxType"
                @click="toggle(row.index)"
              />
            </slot>
          </td>

          <td
            v-for="(header, i) in filteredCompactFields"
            :id="header.key"
            :key="header.key + j"
          >
            <template v-if="i === 0">
              <span
                v-if="openedRow === j"
                class="dropdown-icon icon-arrow-ios-downward-outline"
              ></span>

              <span
                v-else
                class="dropdown-icon icon-arrow-ios-upward-outline"
              ></span>
            </template>

            <slot
              :name="'cell(' + header.key + ')'"
              v-bind="{
                ...row,

                item: row.original,

                value: row.computed[header.key],
              }"
            >
              {{ row.computed[header.key] }}
            </slot>
          </td>

          <td v-if="isSelectable && openedRow === j"></td>

          <td v-show="openedRow === j" class="compact-fields">
            <div
              v-for="header in complementaryCompactFields"
              :key="header.key + j"
              class="flex-compact-fields"
            >
              <div class="header-compact-fields">
                <b>{{ header.label }}:</b>
              </div>

              <div class="content-compact-fields">
                <slot
                  :name="'cell(' + header.key + ')'"
                  v-bind="{
                    ...row,

                    item: row.original,

                    value: row.computed[header.key],
                  }"
                >
                  {{ row.computed[header.key] }}
                </slot>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </tbody>

    <tbody v-else>
      <tr
        v-for="index in perPage"
        :key="'row-loading-' + index"
        style="display: block"
        class="mb-3"
      >
        <td :colspan="filteredCompactFields.length">
          <loader-naf width="100%" height="40px" />
        </td>
      </tr>
    </tbody>

    <tfoot v-if="!isFetching">
      <template v-if="!isCompact">
        <tr
          v-for="(row, j) in rowsFooter"
          :key="j"
          :style="{ 'grid-template-columns': styleGrid }"
          :class="setRowClasses(row.original)"
          @click="onRowClick(row.original, $event)"
        >
          <th
            v-for="header in filteredCompactFields"
            :id="header.key"
            :key="header.key + j"
            :class="header.class"
          >
            <slot
              :name="'cell(' + header.key + ')'"
              v-bind="{
                ...row,

                item: row.original,

                value: row.computed[header.key],
              }"
            >
              {{ row.computed[header.key] }}
            </slot>
          </th>
        </tr>
      </template>

      <template v-else>
        <tr
          v-for="(row, j) in rowsFooter"
          :key="j"
          :style="{ 'grid-template-columns': styleGrid }"
          :class="setRowClasses(row.original)"
          @click="openCompact(j)"
        >
          <td
            v-for="(header, i) in filteredCompactFields"
            :id="header.key"
            :key="header.key + j"
            :class="header.class"
          >
            <template v-if="i === 0">
              <span
                v-if="openedRow === j"
                class="dropdown-icon icon-arrow-ios-downward-outline"
              ></span>

              <span
                v-else
                class="dropdown-icon icon-arrow-ios-upward-outline"
              ></span>
            </template>

            <slot
              :name="'cell(' + header.key + ')'"
              v-bind="{
                ...row,

                item: row.original,

                value: row.computed[header.key],
              }"
            >
              {{ row.computed[header.key] }}
            </slot>
          </td>

          <td v-show="openedRow === j" class="compact-fields">
            <div
              v-for="header in complementaryCompactFields"
              :key="header.key + j"
              class="flex-compact-fields"
            >
              <div class="header-compact-fields">
                <b>{{ header.label }}:</b>
              </div>

              <div class="content-compact-fields">
                <slot
                  :name="'cell(' + header.key + ')'"
                  v-bind="{
                    ...row,

                    item: row.original,

                    value: row.computed[header.key],
                  }"
                >
                  <b>{{ row.computed[header.key] }}</b>
                </slot>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <div class="flex-pagination">
        <pagination
          v-if="(totalItems || filteredItems.length) > perPage"
          v-model="internalPage"
          :rows="totalItems || filteredItems.length"
          :per-page="perPage"
          class="mt-3"
        ></pagination>
      </div>
    </tfoot>
  </table>
</template>

<script>
import DataTable from 'frontendcommon/src/components/design-system/DataTable';

export default {
  name: 'DataTableNaf',

  extends: DataTable,

  props: ['itemsFooter'],

  computed: {
    rowsFooter() {
      if (this.itemsFooter === undefined) {
        return [];
      }

      return this.itemsFooter.map((original, index) => ({
        original,

        computed: this.computeItem(original),

        index,
      }));
    },

    filteredCompactFields() {
      if (this.isCompact) {
        return this.headers.filter(
          (i) => this.compactFields.includes(i.key) || i.key === 'actions'
        );
      } else {
        return this.headers;
      }
    },
  },

  created() {
    // console.log("perpage", this.perPage);
    // console.log("fields", this.fields.length);
    // console.log("isFetching", this.isFetching);
  },
};
</script>

<style lang="scss" scoped>
@import '~/node_modules/frontendcommon/src/scss/config';

td,
th {
  display: flex;

  align-items: center;
}

th {
  font-weight: 500;

  color: $brand-03;
}

td {
  color: $dark-grey;
}

.data-table {
  width: 100%;

  border-radius: 8px;

  display: flex;

  flex-direction: column;

  thead,
  tbody,
  tfoot {
    display: block;

    th,
    td {
      display: flex;

      text-align: left;

      padding-left: 10px;

      padding-right: 30px;

      &:first-child,
      &.td-left {
        text-align: left;
      }
    }
  }

  thead,
  tfoot {
    flex: 0;
  }

  tbody {
    flex: 1;
  }

  thead th {
    padding: 20px 10px;
    padding-right: 30px;
  }

  tbody td {
    padding-left: 10px;

    padding-right: 10px;
  }

  thead th:first-child,
  tbody td:first-child {
    padding-left: 30px;
  }

  thead th:last-child,
  tbody td:last-child {
    padding-right: 30px;
  }

  tbody tr:hover,
  tbody tr.selected {
    background-color: #dff7f9;
  }

  &.stand-alone {
    background: white;

    border: 1px solid $border-color;

    tbody tr:hover {
      background-color: var(--fc-brand-01-soft);
    }
  }

  &.is-selectable {
    thead th:first-child,
    tbody td:first-child {
      padding-left: 30px;

      padding-right: 20px;
    }
  }

  &.is-compact {
    thead th:first-child,
    tbody td:first-child {
      padding-left: 20px;

      padding-right: 0;
    }
  }
}

.is-scrollable {
  tbody {
    overflow-y: auto;
  }
}

.action {
  thead th:last-child,
  tbody td:last-child {
    padding-right: 0 !important;
  }
}

a {
  color: $brand-03 !important;
}

.flex-pagination {
  display: flex;

  justify-content: center;
}

.header-field-text {
  position: relative;
}

.table-sort-icon {
  color: #737373;

  font-size: 16px;

  line-height: 16px;

  vertical-align: middle;

  cursor: pointer;

  position: absolute;

  right: 0;

  top: 50%;

  transform: translate(100%, -50%);

  padding-left: 4px;

  &.active {
    color: var(--fc-brand-01);

    font-weight: bold;
  }
}

.grid {
  display: grid;

  grid-template-rows: 60px 1fr;

  justify-content: center;

  align-items: center;
}

.background:nth-child(odd) {
  background-color: $light-grey;
}

.header-fields {
  display: flex;

  align-items: center;
}

.tooltip-header {
  margin-right: 5px;
}

.tooltip-header-icon {
  font-size: 18px;

  line-height: 16px;

  vertical-align: middle;
}

.tooltip-header-text {
  @include default();

  color: white;
}

.selector-cell {
  padding: 20px;
}

#actions {
  padding-left: 5px;

  padding-right: 15px;
}

.isFetching-container {
  display: flex !important;

  align-content: center;

  justify-content: center;
}

.isFetching {
  color: $medium-grey;

  font-size: 13px;

  letter-spacing: 0px;

  line-height: 20px;

  margin-bottom: 20px;
}

.pointer {
  cursor: pointer;
}

.compact-fields {
  margin-top: 10px;

  margin-bottom: 10px;
}

.flex-compact-fields {
  display: flex;

  padding-left: 60px;

  padding-bottom: 10px;
}

.data-table.is-selectable .flex-compact-fields {
  padding-left: 0;
}

.header-compact-fields {
  flex-basis: 25%;
}

.content-compact-fields {
  flex-basis: 75%;
}

.dropdown-icon {
  font-size: 25px;

  color: $brand-03;

  vertical-align: bottom;

  margin-right: 20px;
}
</style>
